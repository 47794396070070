<!--
 * @Description: 硬件商品信息新增编辑
 * @Author: LiangYiNing
 * @Date: 2021-12-22 11:15:10
 * @LastEditTime: 2022-10-13 09:47:02
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 start -->
          <div class="baseInfo-box">
            <div class="edit-title">商品基础信息</div>
            <div class="content-imgs" :title="`共${fileList.length}图片`">
              <div v-if="fileList.length < 1" class="goods-image">
                <span>无商品图片</span>
              </div>
              <div v-else-if="fileList.length == 1" class="goods-image">
                <div class="img-content">
                  <el-image
                    :src="fileList[0].fileUrl"
                    style="width: 100%; height: 100%"
                    fit="fill"
                    @click="handleContractUploadPreview(fileList[0])"
                  ></el-image>
                  <div class="handle-icon-content">
                    <i
                      class="e6-icon-clear_line"
                      title="移除图片"
                      @click="handleRemove(fileList[0])"
                    ></i>
                  </div>
                </div>
              </div>
              <el-carousel
                height="200px"
                v-if="showCarouselImg"
                :autoplay="false"
                indicator-position="none"
                ref="fileCarousel"
              >
                <el-carousel-item
                  v-for="(file, index) in fileList"
                  :key="file.name + index"
                >
                  <el-image
                    :src="file.fileUrl"
                    style="width: 100%; height: 100%"
                    fit="fill"
                    @click="handleContractUploadPreview(file)"
                  ></el-image>
                  <div class="handle-icon-content">
                    <i
                      class="e6-icon-clear_line"
                      title="移除图片"
                      @click="handleRemove(file)"
                    ></i>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <uploadOBS
                class="avatar-uploader"
                v-model="form.fileIdList"
                :fileType="11"
                :limitSize="1"
                :showFileList="false"
                multiple
                :limitConfig="['png', 'jpg', 'jpeg']"
                buttonText="点击上传"
                :uploadFileList.sync="fileList"
                :canRemove="false"
                @success="handleSuccess"
              >
              </uploadOBS>
            </div>
            <el-form
              ref="form"
              label-width="140px"
              label-position="right"
              disabled
              :inline="true"
              :model="form"
              :rules="rules"
              class="editForm content-form-items"
            >
              <el-form-item label="商品ID" prop="thirdClassId">
                <el-input
                  v-model="form.thirdClassId"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="商品分类(一级设备分类)"
                prop="firstClassName"
              >
                <div slot="label">
                  <div>商品分类</div>
                  <div>(一级设备分类)</div>
                </div>
                <el-input
                  v-model="form.firstClassName"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="商品开票名称(二级设备分类)"
                prop="secondClassName"
              >
                <div slot="label">
                  <div>商品开票名称</div>
                  <div>(二级设备分类)</div>
                </div>
                <el-input
                  v-model="form.secondClassName"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="商品名称(三级设备分类)"
                prop="thirdClassName"
              >
                <div slot="label">
                  <div>商品名称</div>
                  <div>(三级设备分类)</div>
                </div>
                <el-input
                  v-model="form.thirdClassName"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>
              <el-form-item label="固定/移动" prop="fixedOrMoved">
                <el-input
                  v-model="form.fixedOrMoved"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>
              <el-form-item label="所属产品线" prop="productLine">
                <el-input
                  v-model="form.productLine"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>
              <el-form-item label="单位" prop="meaurement">
                <el-input
                  v-model="form.meaurement"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>
              <el-form-item label="类型" prop="typeId">
                <el-input
                  v-model="form.typeId"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>
              <el-form-item label="是否有编号" prop="haveNo">
                <e6-vr-select
                  disabled
                  v-model="form.haveNo"
                  :data="[
                    {
                      codeValue: 1,
                      codeName: '是'
                    },
                    {
                      codeValue: 2,
                      codeName: '否'
                    }
                  ]"
                  placeholder="暂无记录"
                  title="是否有编号"
                  :props="{
                    id: 'codeValue',
                    label: 'codeName'
                  }"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="商品可用状态" prop="usedState">
                <el-input
                  v-model="form.usedState"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="form.remark"
                  placeholder="备注"
                  maxlength="100"
                ></el-input>
              </el-form-item>
              <el-form-item label="是否一次性商品" prop="isOneUse">
                <el-input
                  v-model="form.isOneUse"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="edit-title">商品拓展信息</div>
            <el-form
              ref="form"
              label-width="140px"
              label-position="right"
              :inline="true"
              :model="form"
              :rules="rules"
              class="editForm"
            >
              <div>
                <el-form-item
                  label="设备所属大类"
                  class="search-item--1"
                  prop="equipCategoryCode"
                >
                  <e6-vr-select
                    v-model="form.equipCategoryCode"
                    :data="equipCategoryEnum"
                    placeholder="设备所属大类"
                    title="设备所属大类"
                    clearable
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item
                  label="上架状态"
                  class="search-item--1"
                  prop="shelfStatusCode"
                >
                  <e6-vr-select
                    v-model="form.shelfStatusCode"
                    :data="shelfStatusEnum"
                    placeholder="请选择上架状态"
                    title="请选择上架状态"
                    clearable
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item
                  v-if="form.shelfStatusCode == 3"
                  label="下架原因"
                  prop="removeReason"
                >
                  <el-input
                    v-model="form.removeReason"
                    placeholder="下架原因"
                    title="下架原因"
                    maxlength="128"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  v-if="form.shelfStatusCode == '2'"
                  label="预停售时间"
                  prop="preStopSaleTime"
                >
                  <el-date-picker
                    v-model="form.preStopSaleTime"
                    type="datetime"
                    placeholder="预停售时间"
                    value-format="timestamp"
                    title="预停售时间"
                    :picker-options="pickerOptions1"
                    popper-class="special--full"
                  ></el-date-picker>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="设备保修期(月)" prop="equipWarrantPeriod">
                  <el-input-number
                    size="mini"
                    v-model="form.equipWarrantPeriod"
                    controls-position="right"
                    placeholder="设备保修期(月)"
                    title="设备保修期(月)"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
                <el-form-item
                  label="设备维修时效(天)"
                  prop="equipMaintenTimeLimit"
                >
                  <el-input-number
                    size="mini"
                    v-model="form.equipMaintenTimeLimit"
                    controls-position="right"
                    placeholder="设备维修时效(天)"
                    title="设备维修时效(天)"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
                <el-form-item
                  label="属性自动配置项"
                  class="search-item--1"
                  prop="arributeAotu"
                >
                  <e6-vr-select
                    v-model="form.arributeAotu"
                    :data="thirdClassAttrEnum"
                    placeholder="属性自动配置项"
                    title="属性自动配置项"
                    multiple
                    clearable
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  class="introduction-content-form"
                  label="产品简介"
                  prop="productDescription"
                >
                  <el-input
                    type="textarea"
                    v-model="form.productDescription"
                    :autosize="{ minRows: 3 }"
                    placeholder="产品简介"
                    title="产品简介"
                    maxlength="512"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import goBack from "@/mixins/goBack";
import { printError } from "@/utils/util";
import uploadOBS from "@/components/uploadOBS/uploadOBS";
import {
  updateHardwareGoods,
  hardwareGoodsDetail,
  findDownList,
  downFile
} from "@/api";
import { exportExcelByUrl } from "@/utils/download";
export default {
  name: "IotHardWareUpdate",
  components: { uploadOBS },
  mixins: [base, goBack],
  data() {
    return {
      fileList: [],
      form: {
        thirdClassId: "", // 商品ID
        fileIdList: [], // 上传文件
        firstClassName: "", // 商品分类(一级设备分类)
        secondClassName: "", // 商品开票名称(二级设备分类)
        thirdClassName: "", // 商品名称(三级设备分类)
        fixedOrMoved: "", // 固定移动
        productLine: "", // 所属产品线
        typeId: "", // 类型
        haveNo: "", // 是否有编号
        isOneUse: "", //是否为一次性商品
        usedState: "", // 商品可用状态
        meaurement: "", // 单位
        remark: "", // 备注
        equipCategoryCode: "", // 设备所属大类
        shelfStatusCode: "", // 上架状态
        removeReason: "", // 下架原因
        equipWarrantPeriod: "", // 设备保修期(月)
        equipMaintenTimeLimit: "", // 设备维修时效
        arributeAotu: [], // 属性自动配置项
        productDescription: "" // 产品简介
      },
      shelfStatusEnum: [], // 上架状态枚举
      thirdClassAttrEnum: [], // 商品属性枚举
      equipCategoryEnum: [] // 上架状态枚举
    };
  },
  props: {},
  computed: {
    showCarouselImg() {
      return this.fileList.length > 1;
    },
    pickerOptions1() {
      return {
        disabledDate: time => {
          return time.getTime() < Date.now();
        }
      };
    },
    rules() {
      let rules = {
        equipCategoryCode: [
          {
            required: true,
            message: "请选择商品所属大类",
            trigger: ["blur", "change"]
          }
        ],
        shelfStatusCode: [
          {
            required: true,
            message: "请选择商品上架状态",
            trigger: ["blur", "change"]
          }
        ],
        removeReason: [
          {
            required: true,
            message: "请填写下架原因",
            trigger: ["blur", "change"]
          }
        ],
        preStopSaleTime: [
          {
            required: true,
            message: "请选择停售时间",
            trigger: ["blur", "change"]
          }
        ]
      };
      return rules;
    }
  },
  watch: {},
  created() {
    this.initData();
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    // 初始化表单
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.form.id = hasParamsRoute.params.id;
      if (this.form.id) {
        this.getGoodsInfo();
      }
    },
    // 获取基础信息(枚举)
    async initData() {
      try {
        let promiseList = [
          findDownList(["shelfStatus", "equipCategory", "thirdClassAttr"])
        ];
        let [findDownListRes] = await Promise.all(promiseList);
        this.shelfStatusEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.shelfStatus"
        });
        this.equipCategoryEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.equipCategory"
        });
        this.thirdClassAttrEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.thirdClassAttr"
        });
      } catch (error) {
        printError(error);
      }
    },
    //点击确定新增
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.UpdateGoodInfo();
        }
      });
    },
    //修改商品
    async UpdateGoodInfo() {
      try {
        this.loading = true;
        this.form.fileIdList = this.fileList.map(item => item.id);
        let res = await updateHardwareGoods(this.form);
        if (res.code === "OK") {
          this.$message.success("修改成功");
          this.handleGoBack(true);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取商品详情
    async getGoodsInfo() {
      try {
        this.loading = true;
        let res = await hardwareGoodsDetail({
          id: this.form.id
        });
        this.form = res.data;
        this.form.shelfStatusCode =
          res.data.shelfStatusCode === null ? "" : res.data.shelfStatusCode;
        this.form.shelfStatusCode += "";
        this.form.arributeAotu = this.form.arributeAotu.map(item => item + "");
        this.form.equipCategoryCode = this.form.equipCategoryCode || ""; // 特殊处理0， 后台int 无值时默认为0
        this.form.equipCategoryCode += "";

        let preStopSaleTime = this.form.preStopSaleTime;
        if (preStopSaleTime) {
          preStopSaleTime = preStopSaleTime.replace(/-/g, "/");
          this.form.preStopSaleTime = new Date(preStopSaleTime).getTime();
        }
        // 回显图片
        if (this.form.fileIdList && this.form.fileIdList.length) {
          this.fileList = this.form.fileIdList.map(item => {
            return {
              id: item,
              name: item,
              fileUrl: ""
            };
          });
          this.downFile(this.form.fileIdList);
        } else {
          this.fileList = [];
        }
        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取文件地址
    async downFile(fileIdList = []) {
      try {
        let queryList = fileIdList.map(item => {
          return downFile({ id: item });
        });
        let resList = await Promise.all(queryList);
        fileIdList.map((item, index) => {
          let target = this.fileList.find(one => one.id === item);
          let res = resList[index];
          if (target && res.code === "OK") {
            this.$set(target, "fileUrl", res.data.fileUrl);
          }
        });
        console.log(this.fileList);
      } catch (error) {
        printError(error);
      }
    },
    // // 上传之前的回调
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === "image/jpeg" || file.type === "image/png";
    //   const isLt2M = file.size / 1024 / 1024 < 20;

    //   if (!isJPG) {
    //     this.$message.error("上传图片只能是 JPG/PNG 格式!");
    //   }
    //   if (!isLt2M) {
    //     this.$message.error("上传图片大小不能超过 20MB!");
    //   }
    //   return isJPG && isLt2M;
    // },
    // 点击文件列表中已上传的文件时的钩子
    handleContractUploadPreview(file) {
      if (file.fileUrl) {
        exportExcelByUrl(file.fileUrl);
        return;
      }
      // 点击下载该文件
      if (window.navigator.msSaveBlob) {
        // 兼容IE11+
        window.navigator.msSaveBlob(file.raw, file.name);
      } else {
        // 其他浏览器下载
        const eLink = document.createElement("a");
        eLink.download = file.name;
        eLink.style.display = "none";
        eLink.href = URL.createObjectURL(file.raw);
        eLink.target = "_blank";
        document.body.appendChild(eLink);
        eLink.click();
        URL.revokeObjectURL(eLink.href); // 释放URL 对象
        document.body.removeChild(eLink);
      }
    },
    // 移除时的处理方法
    handleRemove(file) {
      let index = this.fileList.findIndex(item => item.id === file.id);
      if (index !== -1) {
        this.fileList.splice(index, 1);
        this.form.fileIdList = this.form.fileIdList.filter(
          item => item != file.id
        );
      }
    },
    // // 上传文件更改回调downFile
    // handleContractUploadChange(file, fileList) {
    //   this.fileList = fileList;
    // },
    // 上传成功后的回调
    handleSuccess(res) {
      let { code, data } = res;
      let resData = data[0];
      if (code === "OK") {
        this.fileList.map(item => {
          if (!item.id) item.id = resData.fileId;
          return item.id || item.response.data.id;
        });
        // 获取图片地址
        this.downFile([resData.fileId]);
        // 跳转下一张显示
        this.$nextTick(() => {
          if (this.$refs.fileCarousel) this.$refs.fileCarousel.next();
        });
      } else {
        this.fileList = this.fileList.slice(0, -1);
        this.$message.error(res.msg);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

//新增页面form表单样式
.edit-wrapper__body {
  .el-form {
    .el-input {
      width: 250px;
    }
    .el-select {
      width: 250px;
    }
    .el-textarea {
      width: 250px;
    }
  }
}
.address-box {
  display: flex;
  align-items: center;
}
.baseInfo-box {
  .el-input {
    width: 220px;
  }
  .el-select {
    width: 220px;
  }
}
/deep/.edit-wrapper__body {
  .goods-image {
    position: relative;
    height: 200px;
    line-height: 200px;
    text-align: center;
    overflow: hidden;
  }
  .content-imgs {
    position: relative;
    vertical-align: top;
    width: 310px;
    height: 300px;
    margin: 10px 20px 0 40px;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    &:hover .handle-icon-content {
      display: block;
    }
    .handle-icon-content {
      position: absolute;
      display: none;
      top: 0px;
      right: 0px;
      width: 100%;
      height: 16px;
      .e6-icon-clear_line {
        float: right;
        height: 28px;
        margin-right: 0px;
        color: $--color-primary;
        font-size: 12px;
        line-height: 28px;
        font-style: normal;
        background-color: $--color-primary-light-8; // #daf2fb
        text-align: center;
        padding: 0 10px;
        cursor: pointer;
        border-radius: 3px;
      }
    }
    .upload-img {
      margin-top: 10px;
    }
  }
  .content-form-items {
    vertical-align: top;
    width: calc(100% - 380px);
    display: inline-block;
  }
  .introduction-content-form {
    width: 60%;
    .el-form-item__content {
      width: calc(100% - 150px);
      min-width: 200px;
      .el-textarea {
        width: 100%;
      }
    }
  }
}
</style>
